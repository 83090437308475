/** @format */

export enum Environments {
  Production = "production",
  Staging = "staging",
  UAT = "uat",
}

export const RE_CAPTCHA_KEY = "6LclAK0pAAAAABYsENJ_jTA-mEVQNBISul_i8yuk";

const APP_ENV = process.env.APP_ENV || Environments.Production;

export enum Host {
  pms = "pms",
  user = "user",
  userNew = "userNew",
  infra = "infra",
  booking = "booking",
  dealer = "dealer",
  carnival = "carnival",
  retail = "retail",
  discovery = "discovery",
  payment = "payment",
  promo = "promo",
  vehicle1 = "vehicle1",
  vehicle = "vehicle",
  fastagVerticals = "fastagVerticals",
  vehicleVerticals = "vehicleVerticals",
  activity = "activity",
  helpAndSupport = "helpAndSupport",
  valet = "valet",
  valetNew = "valetNew",
  socialDistancingBooking = "socialDistancingBooking",
  socialDistancingActivity = "socialDistancingActivity",
  socialDistancingInfra = "socialDistancingInfra",
  catalog = "catalog",
  qrChat = "qrChat",
  oms = "oms",
  omsNew = "omsNew",
  fulfillment = "fulfillment",
  fulfillmentOriginal = "fulfillmentOriginal",
  parkplusWallet = "parkplusWallet",
  carWash = "carWash",
  carWashOriginal = "carWashOriginal",
  carWashChat = "carWashChat",
  notifications = "notifications",
  notificationGo = "notificationGo",
  content = "content",
  insurance = "insurance",
  insurancev2 = "insurancev2",
  subscription = "subscription",
  storage = "storage",
  fastagIssuance = "fastagIssuance",
  seoService = "seoService",
  tags = "tags",
  promoNew = "promoNew",
  socialCommunity = "socialCommunity",
  socialCommunityNew = "socialCommunityNew",
  magento = "magento",
  gamification = "gamification",
  gamificationNew = "gamificationNew",
  gamificationRedemption = "gamificationRedemption",
  loyaltyWallet = "loyaltyWallet",
  paymentUtils = "paymentUtils",
  contentVerticals = "contentVerticals",
  chat = "chat",
  trip = "trip",
  challanVehicle = "challanVehicle",
  gamificationFuel = "gamificationFuel",
  challan = "challan",
  challanV2 = "challanV2",
  strapi = "strapi",
  bills = "bills",
  mall = "mall",
  yms = "yms",
  oem = "oem",
  homePage = "homePage",
  chariot = "chariot",
  userEngagement = "userEngagement",
  driver = "driver",
  money = "money",
  ppl = "ppl",
  fitbit = "fitbit",
  vehicleRc = "vehicleRc",
  clickStream = "clickStream",
  kelton = "kelton",
  recon = "recon",
  societyVehicle = "societyVehicle",
  search = "search",
}

export const HOST_ENV_MAP: { [key in Host]: string } = {
  [Host.pms]: {
    production: "https://pms-uat.parkplus.io/api",
    uat: "https://pms-uat.parkplus.io/api",
    staging: "https://pms-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.user]: {
    production: "https://user-service.parkwheels.co.in/api",
    uat: "https://user-service.parkwheels.co.in/api",
    staging: "https://user-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.userNew]: {
    production: "https://user-new.parkplus.io/api",
    uat: "https://parkplus-user-uat-v2.parkplus.io/api",
    staging: "https://user-stg-new.parkplus.io/api",
  }[APP_ENV],
  [Host.driver]: {
    production: "https://driver-service.parkplus.io/api",
    uat: "https://driver-uat-service.parkplus.io/api",
    staging: "https://driver-service-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.infra]: {
    production: "https://infra-service.parkwheels.co.in/api",
    uat: "https://infra-uat-test.parkplus.io/api",
    staging: "https://infra-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.booking]: {
    production: "https://booking-v1-service.parkwheels.co.in",
    uat: "https://booking-uat.parkplus.io",
    staging: "https://booking-stg.parkplus.io",
  }[APP_ENV],
  [Host.dealer]: {
    production: "https://dealer-service.parkplus.io/api",
    uat: "https://dealer-uat.parkplus.io/api",
    staging: "https://dealer-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.carnival]: {
    production: "https://carnival-service.parkplus.io/api",
    uat: "https://carnival-service.parkplus.io/api",
    staging: "http://carnival-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.retail]: {
    production: "https://retail.parkplus.io/api",
    uat: "https://retail.parkplus.io/api",
    staging: "https://retail-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.discovery]: {
    production: "https://infra-service.parkwheels.co.in/api",
    uat: "https://infra-service.parkwheels.co.in/api",
    staging: "https://infra-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.payment]: {
    production: "https://payment-service.parkwheels.co.in/api",
    uat: "https://payments-uat.parkplus.io/api",
    staging: "https://payments-uat.parkplus.io/api",
  }[APP_ENV],
  [Host.promo]: {
    production: "https://promocode-service.parkwheels.co.in/api",
    uat: "https://promocode-service.parkwheels.co.in/api",
    staging: "https://promocode-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.vehicle]: {
    production: "https://vehicle.parkplus.io/api",
    uat: "https://vehicle-uat.parkplus.io/api",
    staging: "https://vehicle-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.societyVehicle]: {
    production: "https://vehicle.parkplus.io",
    uat: "https://vehicle-uat.parkplus.io",
    staging: "https://vehicle-stg.parkplus.io",
  }[APP_ENV],
  [Host.fastagVerticals]: {
    production: "https://fastag-vertical.parkplus.io/",
    uat: "https://fastag-vertical-uat.parkplus.io/",
    staging: "https://fastag-vertical-stg.parkplus.io",
  }[APP_ENV],
  [Host.vehicleVerticals]: {
    production: "https://vehicle-verticals.parkplus.io/api",
    uat: "https://vehicle-vertical-uat.parkplus.io/api",
    staging: "https://vehicle-verticals-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.activity]: {
    production: "https://activity.parkplus.io/api",
    uat: "https://activity.parkplus.io/api",
    staging: "https://activity-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.helpAndSupport]: {
    production: "https://cst.parkplus.io/api",
    uat: "https://cst-uat.parkplus.io/api",
    staging: "https://cst-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.valet]: {
    production: "https://booking-v1-service.parkwheels.co.in/valet",
    uat: "https://booking-v1-service.parkwheels.co.in/valet",
    staging: "https://booking-stg.parkplus.io/valet",
  }[APP_ENV],
  [Host.valetNew]: {
    production: "https://valet-service.parkplus.io",
    uat: "https://parkplus-valet-uat.parkplus.io",
    staging: "https://valet-api-stg.parkplus.io",
  }[APP_ENV],
  [Host.socialDistancingBooking]: {
    production: "https://booking-v1-service.parkwheels.co.in",
    uat: "https://booking-uat.parkplus.io",
    staging: "https://booking-stg.parkplus.io",
  }[APP_ENV],
  [Host.socialDistancingActivity]: {
    production: "https://activity.parkplus.io",
    uat: "https://activity.parkplus.io",
    staging: "https://activity-stg.parkplus.io",
  }[APP_ENV],
  [Host.socialDistancingInfra]: {
    production: "https://infra-service.parkwheels.co.in/api",
    uat: "https://infra-uat-test.parkplus.io/api",
    staging: "https://infra-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.catalog]: {
    production: "https://catalog.parkplus.io",
    uat: "https://catalog-uat.parkplus.io",
    staging: "https://catalog-stg.parkplus.io",
  }[APP_ENV],
  [Host.qrChat]: {
    production: "https://qr-production.parkplus.io",
    uat: "https://qr-production.parkplus.io",
    staging: "https://qr-staging.parkplus.io",
  }[APP_ENV],
  [Host.oms]: {
    production: "https://oms.parkplus.io",
    uat: "https://oms-uat.parkplus.io",
    staging: "https://oms-stg.parkplus.io",
  }[APP_ENV],
  [Host.omsNew]: {
    production: "https://oms.parkplus.io/oms",
    uat: "https://oms-uat.parkplus.io/oms",
    staging: "https://oms-stg.parkplus.io/oms",
  }[APP_ENV],
  [Host.fulfillment]: {
    production: "https://fulfillment.parkplus.io/carwash/api",
    uat: "https://fulfillment.parkplus.io/carwash/api",
    staging: "https://fulfillment.parkplus.io/carwash/api",
  }[APP_ENV],
  [Host.fulfillmentOriginal]: {
    production: "https://fulfillment.parkplus.io",
    uat: "https://fulfillment-uat.parkplus.io",
    staging: "https://fulfilment-stg.parkplus.io/carwash/api",
  }[APP_ENV],
  [Host.parkplusWallet]: {
    production: "https://parkplus-wallet.parkplus.io/api",
    uat: "https://parkplus-wallet-uat.parkplus.io/api",
    staging: "https://parkplus-wallet-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.carWash]: {
    production: "https://carwash.parkplus.io/carwash/api",
    uat: "https://carwash.parkplus.io/carwash/api",
    staging: "https://carwash-stg.parkplus.io/carwash/api",
  }[APP_ENV],
  [Host.carWashOriginal]: {
    production: "https://carwash.parkplus.io/api",
    uat: "https://carwash.parkplus.io/api",
    staging: "https://carwash-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.carWashChat]: {
    production: "https://carwash.parkplus.io/chatbox/api",
    uat: "https://carwash.parkplus.io/chatbox/api",
    staging: "https://carwash-stg.parkplus.io/chatbox/api",
  }[APP_ENV],
  [Host.notifications]: {
    production: "https://notification-service.parkwheels.co.in/api",
    uat: "https://notification-service.parkwheels.co.in/api",
    staging: "https://notifications-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.notificationGo]: {
    production: "https://notification-go.parkplus.io/api",
    uat: "https://notification-go.parkplus.io/api",
    staging: "https://notification-go.parkplus.io/api",
  }[APP_ENV],
  [Host.content]: {
    production: "https://content.parkplus.io/api",
    uat: "https://content.parkplus.io/api",
    staging: "https://content-stage.parkplus.io/api",
  }[APP_ENV],
  [Host.insurance]: {
    production: "https://insurance.parkplus.io/api",
    uat: "https://insurance.parkplus.io/api",
    staging: "https://insurance-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.insurancev2]: {
    production: "https://parkplus-insurance-v2.parkplus.io/api",
    uat: "https://insurance-v2-uat.parkplus.io/api",
    staging: "https://insurance-stg-v2.parkplus.io/api",
  }[APP_ENV],
  [Host.subscription]: {
    production: "https://subscription.parkplus.io/api",
    uat: "https://subscription-uat.parkplus.io/api",
    staging: "https://subscription-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.storage]: {
    production: "https://storage.parkplus.io/api",
    uat: "https://storage.parkplus.io/api",
    staging: "https://storage.parkplus.io/api",
  }[APP_ENV],
  [Host.fastagIssuance]: {
    production: "https://fastag-issuance.parkplus.io",
    uat: "https://fastag-issuance-uat-1.parkplus.io",
    staging: "https://fastag-issuance-stg.parkplus.io",
  }[APP_ENV],
  [Host.seoService]: {
    production: "https://seo-service.parkplus.io",
    uat: "https://seo-service.parkplus.io",
    staging: "https://seo-service.parkplus.io",
  }[APP_ENV],
  [Host.tags]: {
    production: "https://parkplus-tags-prod.parkplus.io/api",
    uat: "https://parkplus-tags-prod.parkplus.io/api",
    staging: "https://tags-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.promoNew]: {
    production: "https://promocode.parkplus.io/api",
    uat: "https://promocode-uat.parkplus.io/api",
    staging: "https://promov2-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.socialCommunity]: {
    production: "https://social-community.parkplus.io/api",
    uat: "https://social-community-uat.parkplus.io/api",
    staging: "https://social-community-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.socialCommunityNew]: {
    production: "https://social-community.parkplus.io/api",
    uat: "https://social-community-uat.parkplus.io/api",
    staging: "https://social-community-stg-1.parkplus.io/api",
  }[APP_ENV],
  [Host.magento]: {
    production: "https://store.parkplus.io/rest",
    uat: "https://magento-uat.parkplus.io/rest",
    staging: "https://magento-stage.parkplus.io/rest",
  }[APP_ENV],
  [Host.gamification]: {
    production: "https://gamification-gv.parkplus.io/api",
    uat: "https://parkplus-gamification-gv-uat.parkplus.io/api",
    staging: "https://parkplus-gamification-gv-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.gamificationRedemption]: {
    production: "https://parkplus-gamification-redemption.parkplus.io",
    uat: "https://parkplus-gamification-redemption-uat.parkplus.io",
    staging: "https://parkplus-gamification-redemption-stg.parkplus.io",
  }[APP_ENV],
  [Host.loyaltyWallet]: {
    production: "https://parkplus-loyalty-wallet.parkplus.io/api",
    uat: "https://parkplus-loyalty-wallet-uat.parkplus.io/api",
    staging: "https://parkplus-loyalty-wallet-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.paymentUtils]: {
    production: "https://payment-utils.parkplus.io/api",
    uat: "https://payment-utils-uat.parkplus.io/api",
    staging: "https://payment-utils-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.contentVerticals]: {
    production: "https://content-verticals.parkplus.io/api",
    uat: "https://content-verticals-uat.parkplus.io/api",
    staging: "https://content-verticals-stage.parkplus.io/api",
  }[APP_ENV],
  [Host.chat]: {
    production: "https://chat.parkplus.io/api",
    uat: "https://chat.parkplus.io/api",
    staging: "https://chat-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.trip]: {
    production: "https://trip.parkplus.io/api",
    uat: "https://trip-uat.parkplus.io/api",
    staging: "https://trip-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.challanVehicle]: {
    production: "https://vehicle-challan.parkplus.io/api",
    uat: "https://vehicle-challan.parkplus.io/api",
    staging: "https://vehicle-challan.parkplus.io/api",
  }[APP_ENV],
  [Host.challan]: {
    production: "https://challan.parkplus.io/api",
    uat: "https://challan-uat.parkplus.io/api",
    staging: "https://challan-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.challanV2]: {
    production: "https://challan-v2.parkplus.io/api",
    uat: "https://challan-v2-uat.parkplus.io/api",
    staging: "https://challan-v2-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.strapi]: {
    production: "https://strapi.parkplus.io",
    uat: "https://strapi.parkplus.io",
    staging: "https://strapi.parkplus.io",
  }[APP_ENV],
  [Host.userEngagement]: {
    production: "https://user-engagement.parkplus.io/api",
    uat: "https://user-engagement-uat.parkplus.io/api",
    staging: "https://user-engagement.parkplus.io/api",
  }[APP_ENV],
  [Host.gamificationFuel]: {
    production: "https://parkplus-gamification.parkplus.io/api",
    uat: "https://parkplus-gamification-uat.parkplus.io/api",
    staging: "https://parkplus-gamification-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.gamificationNew]: {
    production: "https://parkplus-gamification.parkplus.io/api",
    uat: "https://parkplus-gamification-uat.parkplus.io/api",
    staging: "https://parkplus-gamification-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.vehicle1]: {
    production: "https://strapi.parkplus.io",
    uat: "https://strapi.parkplus.io",
    staging: "https://strapi.parkplus.io",
  }[APP_ENV],
  [Host.bills]: {
    production: "https://bills.parkplus.io/api",
    uat: "https://bills-uat.parkplus.io/api",
    staging: "https://bills-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.mall]: {
    production: "https://mall.parkplus.io/api/",
    uat: "https://mall-staging.parkplus.io/api/",
    staging: "https://mall-staging.parkplus.io/api/",
  }[APP_ENV],
  [Host.yms]: {
    production: "https://parkplus-yard-management.parkplus.io/api",
    uat: "https://parkplus-yard-management-uat.parkplus.io/api",
    staging: "https://parkplus-yard-management-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.oem]: {
    production: "https://oem.parkplus.io/api",
    uat: "https://oem-uat.parkplus.io/api",
    staging: "https://oem-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.homePage]: {
    production: "https://homepage.parkplus.io/api",
    uat: "https://homepage-uat.parkplus.io/api",
    staging: "https://homepage-uat.parkplus.io/api",
  }[APP_ENV],
  [Host.chariot]: {
    production: "https://chariot-service.parkplus.io/api",
    uat: "https://chariot-uat-service.parkplus.io/api",
    staging: "https://chariot-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.money]: {
    production: "https://money-service.parkplus.io/api",
    uat: "https://money-uat.parkplus.io/api",
    staging: "https://money-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.ppl]: {
    production: "https://ppl.parkplus.io/api",
    uat: "https://vehicle-vertical-uat.parkplus.io/api",
    staging: "https://vehicle-vertical-uat.parkplus.io/api",
  }[APP_ENV],
  [Host.fitbit]: {
    production: "https://fitbit.parkplus.io",
    uat: "https://fitbit-uat.parkplus.io",
    staging: "https://fitbit-uat.parkplus.io",
  }[APP_ENV],
  [Host.vehicleRc]: {
    production: "https://vehicle-rc.parkplus.io",
    uat: "https://vehicle-rc.parkplus.io",
    staging: "https://vehicle-rc-stag.parkplus.io",
  }[APP_ENV],
  [Host.clickStream]: {
    production: "https://clickstream.parkplus.io",
    uat: "https://clickstream.parkplus.io",
    staging: "https://clickstream.parkplus.io",
  }[APP_ENV],
  [Host.kelton]: {
    production: "https://fusion-backend.parkplus.io/api",
    uat: "https://fusion-backend.parkplus.io/api",
    staging: "https://kelton-sandbox-backend.parkplus.io/api",
  }[APP_ENV],
  [Host.recon]: {
    production: "https://recon-service.parkplus.io/api",
    uat: "https://recon-stg.parkplus.io/api",
    staging: "https://recon-stg.parkplus.io/api",
  }[APP_ENV],
  [Host.search]: {
    production: "https://parkplus-search.parkplus.io/api",
    uat: "https://parkplus-search-uat.parkplus.io/api",
    staging: "https://parkplus-search-stg.parkplus.io/api",
  }[APP_ENV],
};
export const API_SECRET = {
  clientId: {
    [Environments.Production]: "8186c1be-660f-428c-93a7-6480c2d8af66",
    [Environments.UAT]: "8186c1be-660f-428c-93a7-6480c2d8af66",
    [Environments.Staging]: "8186c1be-660f-428c-93a7-6480c2d8af66",
  }[APP_ENV],
  clientSecret: {
    [Environments.Production]: "hjjh0uw8c3j7vw5jgba8",
    [Environments.UAT]: "hjjh0uw8c3j7vw5jgba8",
    [Environments.Staging]: "hjjh0uw8c3j7vw5jgba8",
  }[APP_ENV],
};

export const ROLE_ID = {
  supervisor: {
    [Environments.Production]: "1116",
    [Environments.UAT]: "1116",
    [Environments.Staging]: "1116",
  }[APP_ENV],
  technicalAdvisor: {
    [Environments.Production]: "1177",
    [Environments.UAT]: "1177",
    [Environments.Staging]: "1177",
  }[APP_ENV],
  driver: {
    [Environments.Production]: "1117",
    [Environments.UAT]: "1117",
    [Environments.Staging]: "1117",
  }[APP_ENV],
  operator: {
    [Environments.Production]: "2",
    [Environments.UAT]: "2",
    [Environments.Staging]: "2",
  }[APP_ENV],
  tenant: {
    [Environments.Production]: "1113",
    [Environments.UAT]: "1113",
    [Environments.Staging]: "1113",
  }[APP_ENV],
  client: {
    [Environments.Production]: "1",
    [Environments.UAT]: "1",
    [Environments.Staging]: "1",
  }[APP_ENV],
  cleaner: {
    [Environments.Production]: "1134",
    [Environments.UAT]: "1134",
    [Environments.Staging]: "1134",
  }[APP_ENV],
  sales: {
    [Environments.Production]: "1136",
    [Environments.UAT]: "1136",
    [Environments.Staging]: "1136",
  }[APP_ENV],
  cityHead: {
    [Environments.Production]: "1133",
    [Environments.UAT]: "1133",
    [Environments.Staging]: "1133",
  }[APP_ENV],
  switchFastag: {
    [Environments.Production]: "1187",
    [Environments.UAT]: "1187",
    [Environments.Staging]: "1187",
  }[APP_ENV],
  parkplusDriver: {
    [Environments.Production]: "1305",
    [Environments.UAT]: "1305",
    [Environments.Staging]: "1286",
  }[APP_ENV],
  siteTicketingAgent: {
    [Environments.Production]: "1372",
    [Environments.UAT]: "1372",
    [Environments.Staging]: "1372",
  }[APP_ENV],
};

export const VENDOR_ID = {
  buyFastag: {
    [Environments.Production]: "12",
    [Environments.UAT]: "12",
    [Environments.Staging]: "12",
  }[APP_ENV],
  rechargeFastag: {
    [Environments.Production]: "8",
    [Environments.UAT]: "8",
    [Environments.Staging]: "8",
  }[APP_ENV],
  challan: {
    [Environments.Production]: "14",
    [Environments.UAT]: "14",
    [Environments.Staging]: "14",
  }[APP_ENV],
  parking: {
    [Environments.Production]: "15",
    [Environments.UAT]: "15",
    [Environments.Staging]: "15",
  }[APP_ENV],
  extendParking: {
    [Environments.Production]: "19",
    [Environments.UAT]: "19",
    [Environments.Staging]: "19",
  }[APP_ENV],
  carWash: {
    [Environments.Production]: "10",
    [Environments.UAT]: "10",
    [Environments.Staging]: "10",
  }[APP_ENV],
  wallet: {
    [Environments.Production]: "13",
    [Environments.UAT]: "13",
    [Environments.Staging]: "13",
  }[APP_ENV],
  valet: {
    [Environments.Production]: "9",
    [Environments.UAT]: "9",
    [Environments.Staging]: "8",
  }[APP_ENV],
  valetNew: {
    [Environments.Production]: "23",
    [Environments.UAT]: "23",
    [Environments.Staging]: "23",
  }[APP_ENV],
  parkPlusGo: {
    [Environments.Production]: "18",
    [Environments.UAT]: "18",
    [Environments.Staging]: "18",
  }[APP_ENV],
  parkingExit: {
    [Environments.Production]: "21",
    [Environments.UAT]: "21",
    [Environments.Staging]: "21",
  }[APP_ENV],
  carCare: {
    [Environments.Production]: "25",
    [Environments.UAT]: "25",
    [Environments.Staging]: "25",
  }[APP_ENV],
  tagPayment: {
    [Environments.Production]: "27",
    [Environments.UAT]: "27",
    [Environments.Staging]: "27",
  }[APP_ENV],
  tyres: {
    [Environments.Production]: "28",
    [Environments.UAT]: "28",
    [Environments.Staging]: "28",
  }[APP_ENV],
  parkplusStore: {
    [Environments.Production]: "35",
    [Environments.UAT]: "35",
    [Environments.Staging]: "35",
  }[APP_ENV],
  donationDrive: {
    [Environments.Production]: "36",
    [Environments.UAT]: "36",
    [Environments.Staging]: "36",
  }[APP_ENV],
  vouchers: {
    [Environments.Production]: "41",
    [Environments.UAT]: "41",
    [Environments.Staging]: "41",
  }[APP_ENV],
  iocl: {
    [Environments.Production]: "57",
    [Environments.UAT]: "57",
    [Environments.Staging]: "57",
  }[APP_ENV],
  driverSalary: {
    [Environments.Production]: "34",
    [Environments.UAT]: "34",
    [Environments.Staging]: "34",
  }[APP_ENV],
  ucdSubscription: {
    [Environments.Production]: "39",
    [Environments.UAT]: "39",
    [Environments.Staging]: "39",
  }[APP_ENV],
  homeInsurance: {
    [Environments.Production]: "38",
    [Environments.UAT]: "38",
    [Environments.Staging]: "38",
  }[APP_ENV],
  incomeProtect: {
    [Environments.Production]: "38",
    [Environments.UAT]: "38",
    [Environments.Staging]: "38",
  }[APP_ENV],
  smasInsurance: {
    [Environments.Production]: "38",
    [Environments.UAT]: "38",
    [Environments.Staging]: "38",
  }[APP_ENV],
  payPlus: {
    [Environments.Production]: "47",
    [Environments.UAT]: "47",
    [Environments.Staging]: "46",
  }[APP_ENV],
  pickAndDrop: {
    [Environments.Production]: "51",
    [Environments.UAT]: "51",
    [Environments.Staging]: "51",
  }[APP_ENV],
  cstTagReplacement: {
    [Environments.Production]: "11",
    [Environments.UAT]: "11",
    [Environments.Staging]: "11",
  }[APP_ENV],
  invoicePayment: {
    [Environments.Production]: "53",
    [Environments.UAT]: "53",
    [Environments.Staging]: "53",
  }[APP_ENV],
  dealerAddWallet: {
    [Environments.Production]: "47",
    [Environments.UAT]: "47",
    [Environments.Staging]: "47",
  }[APP_ENV],
  billPayment: {
    [Environments.Production]: "44",
    [Environments.UAT]: "44",
    [Environments.Staging]: "44",
  }[APP_ENV],
  vehicleVerification: {
    [Environments.Production]: "56",
    [Environments.UAT]: "56",
    [Environments.Staging]: "56",
  }[APP_ENV],
  mlStore: {
    [Environments.Production]: "59",
    [Environments.UAT]: "59",
    [Environments.Staging]: "59",
  }[APP_ENV],
  masterAddon: {
    [Environments.Production]: "49",
    [Environments.UAT]: "49",
    [Environments.Staging]: "49",
  }[APP_ENV],
  roadAssistance: {
    [Environments.Production]: "38",
    [Environments.UAT]: "38",
    [Environments.Staging]: "38",
  }[APP_ENV],
  societyReactivation: {
    [Environments.Production]: "56",
    [Environments.UAT]: "56",
    [Environments.Staging]: "56",
  }[APP_ENV],
  rcTransfer: {
    [Environments.Production]: "67",
    [Environments.UAT]: "67",
    [Environments.Staging]: "67",
  }[APP_ENV],
  driverOnDemand: {
    [Environments.Production]: "60",
    [Environments.UAT]: "60",
    [Environments.Staging]: "60",
  }[APP_ENV],
  CarInsurance: {
    [Environments.Production]: "73",
    [Environments.UAT]: "73",
    [Environments.Staging]: "73",
  }[APP_ENV],
};

export const ORDER_SKU = {
  valet: {
    [Environments.Production]: "valet",
    [Environments.UAT]: "valet",
    [Environments.Staging]: "valet",
  }[APP_ENV],
  valetNew: {
    [Environments.Production]: "valet",
    [Environments.UAT]: "valet",
    [Environments.Staging]: "valet",
  }[APP_ENV],
  challan: {
    [Environments.Production]: "challan",
    [Environments.UAT]: "challan",
    [Environments.Staging]: "challan",
  }[APP_ENV],
  parking: {
    [Environments.Production]: "parkplus_booking",
    [Environments.UAT]: "parkplus_booking",
    [Environments.Staging]: "parkplus_booking",
  }[APP_ENV],
  extendParking: {
    [Environments.Production]: "extend_parking",
    [Environments.UAT]: "extend_parking",
    [Environments.Staging]: "extend_parking",
  }[APP_ENV],
  fastag: {
    [Environments.Production]: "physical_supertag",
    [Environments.UAT]: "physical_supertag",
    [Environments.Staging]: "",
  }[APP_ENV],
  fastagRecharge: {
    [Environments.Production]: "fastag_recharge",
    [Environments.UAT]: "fastag_recharge",
    [Environments.Staging]: "fastag_recharge",
  }[APP_ENV],
  carWash: {
    [Environments.Production]: "car_wash",
    [Environments.UAT]: "car_wash",
    [Environments.Staging]: "car_wash",
  }[APP_ENV],
  wallet: {
    [Environments.Production]: "parkplus_wallet",
    [Environments.UAT]: "parkplus_wallet",
    [Environments.Staging]: "parkplus_wallet",
  }[APP_ENV],
  parkingExit: {
    [Environments.Production]: "qr_payment",
    [Environments.UAT]: "qr_payment",
    [Environments.Staging]: "qr_payment",
  }[APP_ENV],
  vouchers: {
    [Environments.Production]: "gamification",
    [Environments.UAT]: "gamification",
    [Environments.Staging]: "gamification",
  }[APP_ENV],
  paymentUtils: {
    [Environments.Production]: "payment_utils",
    [Environments.UAT]: "payment_utils",
    [Environments.Staging]: "payment_utils",
  }[APP_ENV],
  homeInsurance: {
    [Environments.Production]: "tata_aig_home_insurance",
    [Environments.UAT]: "tata_aig_home_insurance",
    [Environments.Staging]: "tata_aig_home_insurance",
  }[APP_ENV],
  incomeProtect: {
    [Environments.Production]: "income_protect_insurance",
    [Environments.UAT]: "income_protect_insurance",
    [Environments.Staging]: "income_protect_insurance",
  }[APP_ENV],
  iocl: {
    [Environments.Production]: "iocl_fv",
    [Environments.UAT]: "iocl_fv",
    [Environments.Staging]: "iocl_fv",
  }[APP_ENV],
  payPlus: {
    [Environments.Production]: "pay_plus",
    [Environments.UAT]: "pay_plus",
    [Environments.Staging]: "pay_plus",
  }[APP_ENV],
  pickAndDrop: {
    [Environments.Production]: "dealer_pick_drop",
    [Environments.UAT]: "dealer_pick_drop",
    [Environments.Staging]: "dealer_pick_drop",
  }[APP_ENV],
  cstTagReplacement: {
    [Environments.Production]: "tag_replacement",
    [Environments.UAT]: "tag_replacement",
    [Environments.Staging]: "tag_replacement",
  }[APP_ENV],
  invoicePayment: {
    [Environments.Production]: "invoice_payment",
    [Environments.UAT]: "invoice_payment",
    [Environments.Staging]: "invoice_payment",
  }[APP_ENV],
  dealerAddWallet: {
    [Environments.Production]: "add_money_dealer",
    [Environments.UAT]: "add_money_dealer",
    [Environments.Staging]: "add_money_dealer",
  }[APP_ENV],
  vehicleVerification: {
    [Environments.Production]: "vehicle_verification_flow_through_recharge",
    [Environments.UAT]: "vehicle_verification_flow_through_recharge",
    [Environments.Staging]: "vehicle_verification_flow_through_recharge",
  }[APP_ENV],
  roadAssistance: {
    [Environments.Production]: "road_safety_insurance",
    [Environments.UAT]: "road_safety_insurance",
    [Environments.Staging]: "road_safety_insurance",
  }[APP_ENV],
  societyReactivation: {
    [Environments.Production]: "tag_reactivation",
    [Environments.UAT]: "tag_reactivation",
    [Environments.Staging]: "tag_reactivation",
  }[APP_ENV],
  rcTransfer: {
    [Environments.Production]: "vehicle_rc_transfer",
    [Environments.UAT]: "vehicle_rc_transfer",
    [Environments.Staging]: "vehicle_rc_transfer",
  }[APP_ENV],
  driverOnDemand: {
    [Environments.Production]: "on_demand_driver",
    [Environments.UAT]: "on_demand_driver",
    [Environments.Staging]: "on_demand_driver",
  }[APP_ENV],
  roadSafetyInsuranceAddon: {
    [Environments.Production]: "road_safety_insurance_addon",
    [Environments.UAT]: "road_safety_insurance_addon",
    [Environments.Staging]: "road_safety_insurance_addon",
  }[APP_ENV],
  thirdPartyToaTagReplacement: {
    [Environments.Production]: "THIRDPARTY_TAG_REPLACEMENT",
    [Environments.UAT]: "THIRDPARTY_TAG_REPLACEMENT",
    [Environments.Staging]: "THIRDPARTY_TAG_REPLACEMENT",
  }[APP_ENV],
  cxToaTagReplacement: {
    [Environments.Production]: "i_want_to_get_my_tag_replaced",
    [Environments.UAT]: "i_want_to_get_my_tag_replaced",
    [Environments.Staging]: "i_want_to_get_my_tag_replaced",
  }[APP_ENV],
};

export const ELASTIC_APM_VARIABLES = {
  website: {
    serviceName: "parkplus-website",
  },
};
